import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedFloor: {},
    selectedUnit: {},
    selectedTypology: {},
    amount:null,
    isLoading: true,
    selectedWing: {},
    user:null,
    planId:null,
    isGifLoading:true
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    key: 'userData',
    paths: ['user'],
  })],
  mutations: {
    setUnit(state, unit){
      state.selectedUnit = unit
    },
    setFloor(state, floor){
      state.selectedFloor = floor
    },
    setTypology(state, typology){
      state.selectedTypology = typology
    },
    setLoading(state, flag){
      state.isLoading = flag
    },
    setwings(state, wing){
      state.selectedWing = wing
    },
    setUser(state,user){
      state.user = user;
    },
    setAmount(state,amount){
      state.amount = amount;
    },
    setPlanId(state,planId){
      state.planId = planId
    },
    setIsGifLoading(state,isGifLoading){
      state.isGifLoading = isGifLoading;
    }
  },
  actions: {
    setUnit({commit}, unit){
      commit('setUnit', unit)
    },
    setFloor({commit}, floor){
      commit('setFloor', floor)
    },
    setTypology({commit}, typology){
      commit('setTypology', typology)
    },
    setLoading({commit}, flag){
      commit('setLoading', flag);
    },
    setwings({commit}, wing){
      commit('setwings', wing)
    },
    setUser({commit},user){
      commit('setUser',user);
    },
    setAmount({commit},amount){
      commit('setAmount',amount);
    },
    setPlanId({commit},planId){
      commit('setPlanId',planId);
    },
    setIsGifLoading({commit},isGifLoading){
      commit('setIsGifLoading',isGifLoading);
    }
  },
  getters: {
    selectedUnit(state){
      return state.selectedUnit;
    },
    selectedFloor(state){
      return state.selectedFloor;
    },
    selectedTypology(state){
      return state.selectedTypology;
    },
    isLoading(state){
      return state.isLoading
    },
    selectedWing(state){
      return state.selectedWing;
    },
    user(state){
      return state.user;
    },
    amount(state){
      return state.amount;
    },
    planId(state){
      return state.planId;
    },
    isGifLoading(state){
      return state.isGifLoading;
    }
  }
})