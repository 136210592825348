import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/Inventory.vue')
  },
  // {
  //   path: '/masterplan',
  //   name: 'masterplan',
  //   component: () => import(/* webpackChunkName: "masterplan" */ '../views/MasterPlan.vue')
  // },
  {
    path: '/floorplan',
    name: 'Floorplan',
    component: () => import(/* webpackChunkName: "floorplan" */ '../views/Floorplan.vue'),
    beforeEnter:(to,from,next)=>{
      if(!store.getters.user || !store.getters.user.id){
        next({name:'Inventory'});
      }else{
        next();
      }
        // next();

    }
  },
  {
    path: '/unit',
    name: 'Unit',
    component: () => import(/* webpackChunkName: "unitplan" */ '../views/Unitplan.vue'),
    beforeEnter:(to,from,next)=>{
      if(!store.getters.selectedFloor || !store.getters.selectedFloor.id){
        next({name:'Floorplan'});
      }else{
        next();
      }
    }
  },
  {
    path: '/PaymentDetails',
    name: 'PaymentDetails',
    component: () => import(/* webpackChunkName: "paymentdetails" */ '../views/PaymentDetails.vue'),
    beforeEnter:(to,from,next)=>{
      if(!store.getters.selectedUnit || !store.getters.selectedUnit.id){
        next({name:'Floorplan'});
      }
      else{
        next();
      }
    }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import(/* webpackChunkName: "paymentdetails" */ '../views/Confirmation.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
